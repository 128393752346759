
import styles from '../style/main.module.css'
import { Outlet } from "react-router-dom";
import SideBar from './sideMenu';
import React  from 'react';
const Container = () => {
    return (
        

         <>

        <div className={`${styles.container}`}>
            <div className={styles.container_inner}>
            <SideBar/>
             <Outlet/>
            </div>
       
        </div>
        

    </> 
    
    );
}
 
export default Container;