import axios from "axios"
import { useEffect, useState } from "react"
import Skeleton from "../skeleton/skeleton"
import BlogListing from "./blogList"
import styles from '../../style/main.module.css'

const BlogList = () => {
    const [newsInfo, setNewsInfo] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        axios.get('https://min-api.cryptocompare.com/data/v2/news/?lang=EN')
            .then((response) => {
                setIsLoading(true)
                return response.data.Data
            })
            .then((data) => {
                setNewsInfo(data.slice(0, 20))
                setIsLoading(false)
                //console.log(data.slice(0, 5));
            })
    }, [])
    return (<>
        <div className={styles.main_container}>
            <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={`h2 ${styles.heading_text}`}>
                            All News
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.dashboard_table}>
              
                {isLoading ? <Skeleton type="NewsList"/> : <BlogListing List={newsInfo}/>}
               
            </div>
        </div>
    </>);
}

export default BlogList;