import { Card, CardContent, CardHeader, NewsBody, NewsContent, NewsDiv, NewsHeader, NewsImage, NewsTags } from "./blogComponent";
import styles from '../../style/main.module.css'
import { convertTime } from "../wallet/components/getTime";
const BlogModal = ({blog}) => {

    return (<>{blog && 
    
    <div className="modal fade" id="newsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className= "modal-dialog modal-dialog-centered modal-lg">
    <div className={`  ${styles.card_inner} modal-content `}>
    <div className={`${styles.card_header} modal-header`}>
        <div className={`  ${styles.card_header_text}`} id="newsModalLabel"></div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> 
      <div className={`${styles.card_body} modal-body`}>
      <Card >
                            <CardContent>
                                <CardHeader>
                                    <NewsDiv>
                                        <NewsImage src={blog.imageurl} />
                                        <NewsContent>
                                            <NewsHeader>
                                            {blog.title}
                                            </NewsHeader>
                                            <NewsTags>
                                            Tags:{blog.tags}
                                            </NewsTags>
                                            <NewsBody>
                                                {blog.body}
                                                <div className={styles.note_body_right_time}>{convertTime(blog.published_on)}</div>
                                            </NewsBody>
                                        </NewsContent>
                                    </NewsDiv>
                                </CardHeader>
                            </CardContent>
                        </Card>
      </div>
    </div>
  </div>
</div>
    }
    
    
    </>  );
}
 
export default BlogModal;