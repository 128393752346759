import styled from "styled-components";

export const Footer =styled.footer`
overflow: hidden;
background: var(--background-color);
padding-top: 25px;
}
`

export const FooterTop =styled.div`


`
export const Flex = styled.div`   
 --bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
display: flex;
flex-wrap: wrap;
margin-top: calc(-1 * var(--bs-gutter-y));
`
export const  Container = styled.div`
margin: 0 auto;
padding: 20px;
max-width: 540px;
@media (min-width: 768px){
    max-width: 750px;
}
@media (min-width: 992px){
    max-width: 970px;
}
@media (min-width: 1200px){
    max-width: 1170px;
}

`

export const Card = styled.div`

    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    width: 100%;
    padding: 8px;
    position: relative;
    @media screen and (min-width: 767px)
    {
        width: calc(25% - 16px);
        padding: 16px;
    }`
    export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    `
    export const Column = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    `
    export const ColumnRow = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (min-width: 768px){
        flex-direction: column;
    }`

    export const RowColumn = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px){
        flex-direction: row;
    }`

    export const FooterTitle = styled.div`
    font-size: 17px;
    font-weight: 400;
    color: var(--font-color);
    padding:10px 0;
    @media screen and (min-width: 767px);
    {
        font-weight: 600;
        font-size: 20px;
    }`
    export const FooterList = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    overflow: hidden;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
    max-height: 10em;
    @media screen and (min-width: 767px)
 {
    max-height: 40em;
}
a{
    box-sizing: border-box;
    margin: 0px 0px 12px;
    min-width: 0px;
    display: flex;
    font-size: 14px;
    color: rgb(112, 122, 138);
    @media screen and (min-width: 767px)
    {
       margin-bottom: 12px;
   }
}
`
export const FooterSocial =styled.div`

    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    a{
      
            text-decoration: none;
            box-sizing: border-box;
            margin: 0px 32px 24px 0px;
            min-width: 0px;
            display: inline-flex;
            color: rgb(112, 122, 138);
        
    }
`