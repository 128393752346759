import styled from 'styled-components';

export const ListBlock = styled.div`
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    box-shadow: var(--card-shadow);
    border-radius: 4px;
    background-color: var(--card-background-color);
    padding: 0px 16px;
    width: 100%;

`


export const CardSmall = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    width: 50%;
    padding: 8px;
    animation: skeleton ease 1s infinite alternate;
    @keyframes skeleton {
        to{
         opacity: 0.5;
        }
    }

@media screen and (min-width: 767px)
 {
    width: 33.3%;
}
@media screen and (min-width: 1023px)
{
    width: 16.6667%;
}


`
export const CardContent = styled.div`
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex: 1 1;
    flex-direction: column;`

    export const CardHeader = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    flex: 1 1;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 0;`

    export const CardHeaderContent =  styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    height: 12px;
    line-height: 16px;  
    background-color: var(--background-color);
    width:75%;

    @media screen and (min-width: 767px)
{
    height: 16px;
    line-height: 24px;
}`
export const CardBody = styled.div`
box-sizing: border-box;
margin: 0px 0px 10px 0px;
min-width: 0px;
position: relative;`
 
export const CardBodyContent = styled.a`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
display: flex;
justify-content: center;
flex-direction: column;
padding-bottom: 10px;`

export const CardBodyItemLarge = styled.div`

    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height: 20px;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    background-color: var(--background-color);
    
media screen and (min-width: 767px)
.main_coin_price__lP9n4 {
    height: 24px;
    line-height: 32px;
}`
export const CardBodyItemMedium = styled.div`

    box-sizing: border-box;
    margin: 5px 0;
    min-width: 0px;
    height: 12px;
    font-weight: 700;
    line-height: 24px;
    width: 50%;
    background-color: var(--background-color);
    
media screen and (min-width: 767px)
.main_coin_price__lP9n4 {
    height: 14px;
    line-height: 32px;
}`
export const NewsDiv = styled.div`
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    align-items: center;
    height: 52px;
    border-radius: 4px;
    background-color: var(--card-background-color);`
    
    export const NewsHeader = styled.div`

    box-sizing: border-box;
    margin: 0px 0px 8px 0px;
    min-width: 0px;
    height: 70px;
    line-height: 20px;
    background-color: var(--background-color);
    
`
export const NewsImage = styled.div`

width: 32px;
height: 32px;
flex-shrink: 0;
border-radius: 100%;
background-color: var(--background-color);
`

export const NewsContent = styled.div`
box-sizing: border-box;
    margin: 0px 0px 0px 24px;
    min-width: 0px;
    display: flex;
    height: 64px;
    justify-content: center;
    flex-direction: column;
    width:100%`

    export const NewsBody = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    height:35px;
    background-color: var(--background-color);`

    export const Card = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
display: flex;
width: 100%;
padding: 8px;
animation: skeleton ease 1s infinite alternate;
@keyframes skeleton {
    to{
     opacity: 0.5;
    }
}

`