import { Outlet } from 'react-router-dom';
import styles from '../css/index.module.css'
const IndexBody = () => {
    return (<>
        <div className={`${styles.container}`}>
            <div className={styles.container_inner}>
                <Outlet/>
            </div>
        </div></>);
}

export default IndexBody;