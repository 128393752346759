import './css/style.css'
import { Link, useNavigate  } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import  './css/loadProgressMin.css'
import React  from 'react';
import { GetRandomInt, GetRandomString } from '../market/pages/wallet/components/random';
import Cookies from 'js-cookie'
import { AuthContainerAlt, AuthTextbox, AuthTextFlex, AuthTextgroup, FlexColumn, Icon, IconBody, IconDiv, Wrapper } from './components/componentStyles';
import AuthHeaders from './components/AuthHeader';
 
const Login = () => {
	const [email, setEmail]=useState('');
	const [password, setPassword]=useState('');
	const [loginBtn, setloginBtn]= useState(true);
	const [loginResponse, setLogingresponse] = useState('');
	const [passwordShown, setPasswordShown] = useState(false);

	const navigate  = useNavigate ();

	axios.defaults.withCredentials = true;
	const loginUser = (e)=> {
		e.preventDefault();
		setloginBtn(false)
		
		axios.post('https://api.profoliomarket.com/user/login',{
			useremail:email,
			password:password
		}).then((response)=>{
			//console.log(response)
				// check if userEmail is verified
						let email= response.data.user.email
						let userId = response.data.user.user_id
					if (response.data.user.verificationStatus === '0'){
						const token = GetRandomString(62)
						
						let userName = response.data.user.firstName + ' ' + response.data.user.lastName
						navigate('/verify', {state: {email:email, userId:userId, token:token, userName:userName}})						
					}
					else if (response.data.user.firstName === null || response.data.user.lastName === null) {
						navigate('/userBios', {state: {email:email, userId:userId}})
					}
					else if (response.data.user.country === null || response.data.user.currency_id === null) {
						navigate('/currency', {state: {email:email, userId:userId}})
					}
					else {
							const OTP = GetRandomInt(6);
						axios.get(`https://api.profoliomarket.com/mailer/emailOTP?mailTo=${email}&token=${OTP}`).then(()=>{
						const time = Math.floor(Date.now() / 1000)
						const expires = Math.floor(time + 600)  //10 minutes
						const messageId = GetRandomString(36)
						
						axios.post('https://api.profoliomarket.com/auth/sendOTP',{
							userId: userId,
							messageId: messageId,
							code: OTP,
							time: time,
							expires: expires
								})
								.then(()=> {
									navigate('/auth', {state: {user:response.data.user, messageId:messageId}})
								}) 
						})
						.catch(err => {
							console.error(err)
						})
					
												
					}
				
			
		}).catch((error)=>{
			if (error){
				const msg = error.response.data.message
				setLogingresponse(<div className='alert alert-danger p-2 alert-dismissible fade show' role="alert">{msg}</div>);
				setloginBtn(true)
				setTimeout(function () {setLogingresponse('') }, 10000)
			}
		})
	}

	useEffect(()=>{

		const check = Cookies.get('__isLoggedIn')
		
		if (check) {
		const userInfo = JSON.parse(localStorage.getItem('_currentInfo'))
		const updateInfo = {
			country: userInfo.country,
			currency_id: userInfo.currency_id,
			demo: userInfo.demo,
			deposit: userInfo.deposit,
			email: userInfo.email,
			firstName: userInfo.firstName,
			lastLog: userInfo.lastLog,
			phone: userInfo.phone,
			lastName: userInfo.lastName,
			user_id: userInfo.user_id,
			verificationStatus: userInfo.verificationStatus,
			phoneVerified: userInfo.phoneVerified
		}
		localStorage.setItem('_currentInfo', JSON.stringify(updateInfo))
		axios.post('https://api.profoliomarket.com/user/getFiat',{currencyId:userInfo.currency_id})
						.then((response)=>{
						  const curr =  response.data.currency
						  let sym = curr.symbol
						  let result = sym.substring(1, 2);
						  const items ={
							name: curr.name,
							symbol: result
						  }
						  localStorage.setItem('OMS__CUR', JSON.stringify(items))
						  navigate('/home/dashboard')
						})
			// navigate('/home/dashboard')
		}
	},[navigate])
	const hideState = <Icon className="fi fi-sr-eye"></Icon>
	const showState = <Icon className="fi fi-sr-eye-crossed"></Icon>
	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	  };

	// Move these outside the render to avoid recreating on each render
	const LoginButton = ({ isLoading }) => (
		isLoading ? (
			<button className="btn btn-warning" type="submit" disabled>
				<div className="lds-ring">
					{[...Array(4)].map((_, i) => <div key={i}></div>)}
				</div>
			</button>
		) : (
			<button className="btn btn-warning" type="submit">
				Login
			</button>
		)
	);

	return (
		<>
			<AuthHeaders />
			<Wrapper>
				<div className="container-fluid user-auth row">
					<AuthContainerAlt>
						<div className="form-container">
							<FlexColumn>
								<div className="text-center">
									<h2 className="title-head h3">
										member <span className="text-warning">login</span>
									</h2>
									<p className="info-form">
										Send, receive and securely store your coins in your wallet
									</p>
								</div>

								<form onSubmit={loginUser}>
									{loginResponse && <div className="mt-10">{loginResponse}</div>}

									<AuthTextgroup className="mb-4">
										<AuthTextFlex>
											<AuthTextbox
												name="email"
												id="txt_uname"
												placeholder="EMAIL"
												type="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												required
												aria-label="Email address"
											/>
										</AuthTextFlex>
									</AuthTextgroup>

									<AuthTextgroup className="mb-4">
										<AuthTextFlex>
											<AuthTextbox
												name="password"
												id="txt_pwd"
												placeholder="PASSWORD"
												type={passwordShown ? "text" : "password"}
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												required
												aria-label="Password"
											/>
											<IconDiv className="icon-div">
												<IconBody
													id="pass-display"
													onClick={togglePassword}
													onKeyDown={(e) => e.key === 'Enter' && togglePassword()}
													role="button"
													tabIndex={0}
													aria-label={passwordShown ? "Hide password" : "Show password"}
												>
													{passwordShown ? showState : hideState}
												</IconBody>
											</IconDiv>
										</AuthTextFlex>
									</AuthTextgroup>

									<FlexColumn>
										<LoginButton isLoading={!loginBtn} />
										
										<p className="text-center">
											<Link to="/forgot" className="text-warning">
												forgot password
											</Link>{" "}
											| don't have an account?{" "}
											<Link to="/register" className="text-warning">
												Register Now
											</Link>
										</p>
										<br />
										<p className="text-center">
											<Link to="/" className="text-warning">
												Back to Home
											</Link>
										</p>
									</FlexColumn>
								</form>
							</FlexColumn>
						</div>

						<p className="text-center copyright-text">
							Copyright © {new Date().getFullYear()} Profolio Market All Rights Reserved
						</p>
					</AuthContainerAlt>
				</div>
			</Wrapper>
		</>
	);
};

export default Login;
