import React, { Component, useState } from 'react';
import Webcam from "react-webcam";
import { CameraButton, FlexColumn, YellowButton } from './bodyStyle';

const WebcamComponent = () => <Webcam />;
const videoConstraints = {
    width: 340,
    height: 200,
    facingMode: "user"
};

const WebcamCapture = () => {
    const webcamRef = React.useRef(null);

    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            console.log(imageSrc)
            setImage(imageSrc)
        },

        [webcamRef]

    );
    const [image, setImage] = useState('');
    return (<>
       <FlexColumn>
            {image === '' ? <Webcam
                audio={false}
                height={200}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={340}
                videoConstraints={videoConstraints}
            style={{width:'auto'}}/> : <img src={image} />}
             <CameraButton>
             {image !== '' ?
                <YellowButton onClick={(e) => {e.preventDefault(); setImage('') }} > Retake Image</YellowButton> :
                <YellowButton onClick={(e) => {e.preventDefault();capture(); }}>Capture</YellowButton>
            }
            </CameraButton>
        </FlexColumn>
       
    </>);
};
export default WebcamCapture