import AffiliateBody from "./affiliate/affiliateBody";

const Affiliate = () => {

    return ( <>
    
    <AffiliateBody/>
    
    </> );
}
 
export default Affiliate;