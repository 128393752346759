import React, { useEffect, useState } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import styles from '../style/main.module.css'
import AllTrades from './trade/allTrades';
import OpenTrades from './trade/openTrade';
import TraderSummary from './trade/traderSummary';
import { FlexColumn,   TextListIcon,  YellowButton,  TextLabel,  H3, H4, ListHeading, ListBody, IDtype,  MiniIcon } from './wallet/components/bodyStyle';
import WebcamCapture from './wallet/components/camera';


class DashboardTrade extends React.Component {
state = {
    countrySelected:false,
    processedUserData:[],
    country: ''
}


    render() {
        
       
      
     const PersonlInfo = () => { 
         const processCurrency = (e) =>{
            e.preventDefault();
            if (country !== '' ){
                setVerifiedStatus(true);
            }
        }
        // const processCardType =(e) =>{
        //     e.preventDefault();
        // }
        const [country, setCountry] = useState('')
        const [verifiedStatus, setVerifiedStatus] = useState()
        const [idType, setIdType] = useState(0)
        const [idName, setIdName] = useState()
        if (!verifiedStatus){
 return (<>
            <FlexColumn>
        <ListHeading>
        <H3>Select issuing country</H3>
            <H4>Please select the country that issued your ID.</H4>
        </ListHeading>
        <ListBody> 
            <CountryDropdown  className={styles.selectContainer} value={country} onChange={(val) => {setCountry(val); }} />   
        </ListBody>
        <YellowButton onClick={processCurrency}>Proceed</YellowButton>
           </FlexColumn>
           {/* <TextGroup> */}
            {/* <DisplayHalf> */}
            {/* <TextLabel>First Name</TextLabel>
            <VerificationTextBox name="first-name" placeholder="First Name"/>   */}
            {/* </DisplayHalf> */}
            {/* <DisplayHalf>
            <TextLabel>Last Name</TextLabel>
            <VerificationTextBox name="last-name" placeholder="Last Name"/>  
            </DisplayHalf>            */}
        {/* </TextGroup> */}
    
    </>)

        }
        else{
            if (idType === 0){
                return (  <> <FlexColumn>
                  <ListHeading>
        <H3>Select ID type</H3>
            <H4>Kindly select your prefarred card type</H4>
        </ListHeading>
        <ListBody>
                <FlexColumn>
                    <IDtype role="button" onClick={(e)=>{setIdType(1); setIdName('Drivers Lincense')}} focus-visible='false'>
                    
                    <TextLabel>Orders Lincense</TextLabel>
                    <TextListIcon>
                       <MiniIcon src={ require(`../style/icons8/icons8-driver-license-96.png`) }/>
                       </TextListIcon>
                    </IDtype>
                    <IDtype role="button" onClick={(e)=>{setIdType(2); setIdName('State Id Card')}} focus-visible='false'>
                    
                    <TextLabel>State ID Card</TextLabel>
                    <TextListIcon>
                       <MiniIcon src={ require(`../style/icons8/icons8-identification-documents-96.png`) }/>
                       </TextListIcon>
                    </IDtype>
                    <IDtype role="button" onClick={(e)=>{setIdType(3); setIdName('International Passport')}} focus-visible='false'>
                   
                    <TextLabel>International Passport</TextLabel> 
                    <TextListIcon>
                       <MiniIcon src={ require(`../style/icons8/icons8-passport-96.png`) }/>
                       </TextListIcon>
                    </IDtype>
                    </FlexColumn>
        </ListBody>
       
                </FlexColumn></>)
            }
            else{
                return (<>
                <FlexColumn>
                <ListHeading>
        <H3>{idName}</H3>
            <H4>Kindly Snap the front side of your {idName}</H4>
                < WebcamCapture/>
          
        </ListHeading>
        {/* <YellowButton onClick={processCardType}>Proceed</YellowButton> */}
                </FlexColumn>
                </>)
            }
            
        }
   
}
//const x = this.state.countrySelected
const Trade = () => {
    const [verified, setVerified] = useState()
    const [balance, setBalance] = useState()
   // const [currency, setCurrency] = useState()
    const [currSym, setCurrSym] = useState()
    const [userId, setUserId] = useState()

useEffect(()=>{
  const userInfo = localStorage.getItem('_currentInfo')
  const currency = JSON.parse(localStorage.getItem('OMS__CUR'))
    //setCurrency(currency.name)
    setCurrSym(currency.symbol)
    const users = JSON.parse(userInfo)
    setBalance(users.deposit)
    setUserId(users.user_id)
    if (users.verificationStatus === "0") {
        setVerified('Invalid user')
    } else if (users.verificationStatus === "1") {
        setVerified('Unverified')
    } else if (users.verificationStatus === "2" ) {
        setVerified('Under Review')
    } else if (users.verificationStatus === "3" ) {
        setVerified('Verified')
    }
    },[]) 
   
    return (  <>
    
    <div className={styles.main_container}>
    <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={`h2 ${styles.heading_text}`}>
                            Orders 
                        </div>
                    </div> 
                </div>
    </div>
            <div className={styles.dashboard_table}>
                <div className={`${styles.card} ${styles.sm}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={` ${styles.balance} ${styles.coin_title} h5`}>Summary</div>
                                    <div className={styles.dashboard_welcome_verification}>
                                        <i className="fi fi-sr-file-delete"></i> {verified}</div>
                                </div>
                            </div>
                            <div className={styles.card_body}>
                              {userId &&  <TraderSummary balance={balance} currency={currSym} userId={userId}/>} 
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.md}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={` ${styles.balance} ${styles.coin_title} h5`}>Opened Orders</div>
                                </div>
                            </div>
                            <div className={styles.card_body}>
                                {userId && <OpenTrades userId={userId}/>}
                               
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.lg}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={` ${styles.balance} ${styles.coin_title} h5`}>Closed Orders</div>
                                </div>
                            </div>
                            <div className={styles.card_body}>
                               {userId &&  <AllTrades userId={userId}/>  }                         
                                 </div>
                           
                        </div>
                    </div>
                </div>

            </div>

    </div>
    <div className="modal fade" id="AccountVerification" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className= "modal-dialog modal-md">
    <div className={`  ${styles.card_inner} modal-content `}>
    <div className={`${styles.card_header} modal-header`}>
        <div className={`  ${styles.card_header_text}`} id="AccountVerificationLabel">{/*modalType*/}Verify your Identity</div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> 
      <div className={`${styles.card_body} modal-body`}>        
          
          <PersonlInfo/>
      </div>
    </div>
  </div>
</div>
    </>);
}  

       return (<>
       <Trade/>
       </>)

    }
}

 
export default DashboardTrade;