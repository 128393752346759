export const convertTime =(timestamp)=>{
    var t = new Date(timestamp * 1000);
var hours = t.getHours();
var minutes = t.getMinutes();
var newformat = t.getHours() >= 12 ? 'PM' : 'AM';  
hours = hours % 12;  
hours = hours ? hours : 12;  
minutes = minutes < 10 ? '0' + minutes : minutes; 
var formatted = 
    (t.toString().split(' ')[0]) 
    + ', ' +('0' + t.getDate()).slice(-2) 
    + '/' + ('0' + (t.getMonth() + 1) ).slice(-2)
    + '/' + (t.getFullYear())
    + ' - ' + hours
    + ':' + minutes
    // + ' - ' + ('0' + t.getHours()).slice(-2)
    // + ':' + ('0' + t.getMinutes()).slice(-2)
    + ' ' + newformat;

    return formatted;
}
 