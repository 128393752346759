import styles from '../../style/main.module.css'
import { ComingSoon } from './styled';
const WallectConnect = () => {
    return ( <>
     <div className={styles.main_container}>
            <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={`h2 ${styles.heading_text}`}>
                        Connect Wallet
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.dashboard_table}>
            <div className={`${styles.card} ${styles.lg}`} >
                      <div className={styles.card_inner}>
                          <div className={styles.card_content}>
                            <ComingSoon>
                                Coming Soon
                            </ComingSoon> 
                            </div>
                            </div>

                  
                    </div>
 
            </div>
            
  </div>
    </> );
}
 
export default WallectConnect;