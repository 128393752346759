import styled from 'styled-components';

export const Main = styled.div`
    padding: 15%;
    margin: 0 auto;
    flex-direction: column;
    display: flex;
    width: 75%;
    @media only screen and (max-width: 728px) {
        margin: 30px 0;
        padding: 10% !important;
        width: 100%;
      }
    `
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: auto;

    `
export const Title = styled.div`
    font-size: 30px;
    font-weight: 600;
    color: var(--font-color);
    margin-bottom: 20px;
    `
export const Wrapper = styled.div`
    background-color: var(--background-color);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;`
export const Logo = styled.div`
    padding: 15px;
    background-color: var(--bs-body-color);
    `
    export const LogoInner = styled.div`
    display: block!important;
    `
    export const Img = styled.img`
    margin: 0 auto;
    width:100px
    `
    export const Text = styled.div`
    color: var(--font-color);
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    display: flex`

export const Text2 = styled.div`
color: var(--font-color);
font-weight: 500;
font-size: 18px;
line-height: 28px;
align-self: center;
margin-left: 10px;`

export const Icon = styled.i`
font-size: 70px;
align-content: flex-end;
color: #ffcd00;`