import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import BlogListing from '../../market/pages/blogs/blogList';
import Skeleton from '../../market/pages/skeleton/skeleton';
import styles from '../css/index.module.css'
import { H2, IndexSection } from '../style/bodyStyle';

const IndexInsights = () => {
    const [newsInfo, setNewsInfo] = useState()
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        axios.get('https://min-api.cryptocompare.com/data/v2/news/?lang=EN')
            .then((response) => {
                setIsLoading(true)
                return response.data.Data
            })
            .then((data) => {
                setNewsInfo(data.slice(0, 20))
                setIsLoading(false)
                //console.log(data.slice(0, 5));
            })
    }, [])
    return (<>

    <div className={styles.main_container}>
    <IndexSection>
                <H2>
                    Latest Insights
                </H2>
</IndexSection>
    {isLoading ? <Skeleton type="NewsList"/> : <BlogListing List={newsInfo}/>}
     </div> 
   
    </> );
}
 
export default IndexInsights;