import styled from 'styled-components';

export const Upload = styled.div`
cursor: pointer;
height: 150px;
width: 150px;
background: #ffc107;
padding: 50px 40px;
color: var(--font-color-inverse);
border-radius: 5px;
transition: 0.5s;
margin: auto;
:hover{
    background: #ffbf0066;
    color: var(--font-color);
}
`

export const UploadLabel = styled.label`
    cursor: pointer;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 13px;
    text-align: center;
    line-height: 1.5;
`
export const UploadFile = styled.input`
    display: none;
    opacity: 0;
    position: absolute;
    z-index: -1;
`
export const Clearfix = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
min-height: 20px;
}`
export const YellowButton = styled.button`
@media screen and (min-width: 767px)
{
    min-width: 120px;
}

    margin: 0px;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    word-break: keep-all;
    color: rgb(33, 40, 51);
    border-radius: 4px;
    min-height: 24px;
    border: none;
    background-image: none;
    background-color: #ffc107;
    height: 40px;
    min-width: 100%;

    :hover {
        background-color: #ffcb2d;
        transition:cubic-bezier(0.6, -0.28, 0.74, 0.05) 0.5s;
    }
`
export const GreenButton = styled.button`
@media screen and (min-width: 767px)
{
    min-width: 120px;
}

    margin: 0px;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    word-break: keep-all;
    color: var(--font-color-inverse);
    border-radius: 4px;
    min-height: 24px;
    border: none;
    background-image: none;
    background-color: #00b10a;
    height: 40px;
    min-width: 100%;

    :hover {
        background-color: #01eb0f;
        transition:cubic-bezier(0.6, -0.28, 0.74, 0.05) 0.5s;
    }
`
export const FlexColumn = styled.div`
display:flex;
flex-direction:column;`
export const CardContent = styled.div`
@media screen and (min-width: 767px)
{
    align-items: flex-start;
}
    box-sizing: border-box;
    margin: 0px 0px 12px 0;
    min-width: 0px;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;`
export const FlexRow = styled.div`
display:flex;
flex-direction:row;`

export const TextList = styled.div`
display:flex;
flex-direction:row;
color:var(--font-color);`
export const TextListIcon = styled.div`
display:block;
`
export const TextListItem = styled.div`
margin-left:10px;
line-height:1.75
`
export const TextGroup = styled.div`
flex-direction: column;
display: flex;
margin-bottom: 5px;
padding: 0 10px;
@media screen and (min-width: 768px){
flex-direction: row;
}
`
export const TextLabel = styled.label`
padding: 6px;
font-size: 15px;
font-weight: 700;`
export const IconWrapper = styled.div`
margin-left: 5px;
    padding: 0px 5px;
    color: var(--success);
    font-size: 12px;
    height: 22px;
    align-content: center;
    cursor: pointer;`

    export const IconWrapperFailed = styled.div`
margin-left: 5px;
    padding: 0px 5px;
    color: var(--failed);
    font-size: 12px;
    height: 22px;
    align-content: center;
    cursor: pointer;`
    export const IconWrapperNot = styled.div`
    margin-left: 5px;
        padding: 0px 5px;
        color: var(--warning);
        font-size: 12px;
        height: 22px;
        align-content: center;
        cursor: pointer;`

export const AuthTextgroup = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
position: relative;
min-height: 12px;
`
export const IconDiv = styled.div`
box-sizing: border-box;
    margin: 0;
    min-width: 0;`
    
export const IconBody = styled.div`
box-sizing: border-box;
    margin: 0px 4px 0px 0px;
    min-width: 0px;
    display: flex;
    align-items: center;
    :hover{
        color: var(--warning);
    }
    `
export const Icon = styled.i`
box-sizing: border-box;
    margin: 0px 0px 0px 4px;
    min-width: 0px;
    color: rgb(94,102,115);
    font-size: 15px;
    width: 1em;
    height: 1em;
`
    export const Text = styled.div`
    margin-left: 5px;
    height: 1rem;
    font-size: 16px;
    line-height: 1.2;
    color: rgb(94,102,115);
    cursor: pointer;
    :hover{
        color: var(--warning);

}`
export const AuthTextbox = styled.input`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
height: 100%;
padding: 0px;
outline: none;
border: none;
background-color: inherit;
opacity: 1;
`
export const AuthTextFlex = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
display: inline-flex;
position: relative;
align-items: center;
line-height: 1.6;
border: 1px solid rgb(71, 77, 87);
border-radius: 4px;
height: 48px;
background-color: transparent;
width: 100%;
input{
    color: var(--font-color);;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
}
input:-webkit-autofill{
    transition: color 99999s ease-in-out 0s, background-color 99999s ease-in-out 0s;
    -webkit-text-fill-color: var(--font-color);;
}
:hover{
    border-color: var(--warning);
}
${IconDiv}{
    flex-shrink: 0;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 14px;
}
.PhoneInput{ 
    width: 100%;
    padding: 0 10px;
}
.PhoneInputInput{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    height: 100%;
    padding: 0px;
    outline: none;
    border: none;
    background-color: inherit;
    opacity: 1;
}`

export const LoadingLg = styled.div`

    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    margin:auto;
    
    div {
    box-sizing: border-box;
      display: block;
      position: absolute;
      width: 60px;
      height: 60px;
      margin: 0px;
      border: 3px solid #ffce00;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #ffce00 transparent transparent transparent;
    
      nth-child(1) {
    animation-delay: -0.45s;
  }
    nth-child(2) {
    animation-delay: -0.3s;
  }
    nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }`
  export const LoadingSm = styled.div`

    display: inline-block;
    position: relative;
    width: 25px;
    height: 25px;
    margin:auto;
    
    div {
    box-sizing: border-box;
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      margin: 0px;
      border: 3px solid #ffce00;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #ffce00 transparent transparent transparent;
    
      nth-child(1) {
    animation-delay: -0.45s;
  }
    nth-child(2) {
    animation-delay: -0.3s;
  }
    nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }`

  export const PhoneVerification = styled.div`
  align-self: center;
  font-size: 40px;
  color: lime;
  flex-direction: column;
  display: flex;`

  export const PhoneVerificationText = styled.div`
  font-size: 14px;
    text-align: center;
  `
    export const PhoneVerificationIcon = styled.div`
    text-align: center;`
    export const PhoneVerificationIconButton = styled.button` 
    @media screen and (min-width: 767px)
{
    min-width: 120px;
}

    margin: 0px;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    word-break: keep-all;
    color: rgb(33, 40, 51);
    border-radius: 4px;
    min-height: 24px;
    border: none;
    background-image: none;
    background-color: #ffc107;
    height: 40px;
    min-width: 100%;

    :hover {
        background-color: #ffcb2d;
        transition:cubic-bezier(0.6, -0.28, 0.74, 0.05) 0.5s;
    }
    `
    export const TextError = styled.div`
    color: #ff5050;
    font-style: italic;
    `
    
export const Table = styled.table`
background-color:var(--table-background-color);
box-sizing: border-box;
min-width: 0px;
color: var(--font-color);
border-collapse: inherit;`

export const Tr = styled.tr`

:hover{
background-color:var(--table-row-hover);
}
`
export const Td = styled.td`
border-width: 0px !important;`

export const NotFound = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: 10% auto;
color: #d5d5d5;
`
export const NotFoundIcon = styled.i`
font-size: 40px;

`
export const NotFoundText = styled.div`
font-size: 16px;
padding: 10px;
`