import styles from '../../style/main.module.css'
import { Banner, FlexRow } from './style';
const RefBanner = () => {
    return ( <>
    <Banner className={`${styles.dashboard_banner}`}>
                <div className={` ${styles.dashboard_banner_inner}`}>
                    <div className={` ${styles.dashboard_banner_content}`}>
            
                        <FlexRow>
                            
                            <div className={styles.dashboard_welcome_balance_logo_inner}>
                               <i className={`fi fi-sr-share ${styles.dashboard_welcome_balance_logo}`}></i>
                            </div>
                            <span>
                                <div className={styles.dashboard_welcome_balance_text_header}>Share your referral link with friends</div>
                                <div className={styles.dashboard_welcome_balance_text}>
                                    {/* <CurrencyFormat value={accountBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={`${currencySymbols}`} /> 
                                    <Link to="#" className={styles.dashboard_welcome_balance_currency}>{currency}</Link> */}
                                </div>
                            </span>
                        </FlexRow>
                        <FlexRow>
                            
                            <div className={styles.dashboard_welcome_balance_logo_inner}>
                               <i className={`fi fi-sr-money-bill-wave-alt ${styles.dashboard_welcome_balance_logo}`}></i>
                            </div>
                            <span>
                                <div className={styles.dashboard_welcome_balance_text_header}>Invite friends to sign up and deposit up to $100</div>
                                <div className={styles.dashboard_welcome_balance_text}>
                                    {/* <CurrencyFormat value={accountBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={`${currencySymbols}`} /> 
                                    <Link to="#" className={styles.dashboard_welcome_balance_currency}>{currency}</Link> */}
                                </div>
                            </span>
                        </FlexRow>
                        <FlexRow >
                            
                            <div className={styles.dashboard_welcome_balance_logo_inner}>
                               <i className={`fi fi-sr-gifts ${styles.dashboard_welcome_balance_logo}`}></i>
                            </div>
                            <span>
                                <div className={styles.dashboard_welcome_balance_text_header}>Recieve your cash back of 10% for each account</div>
                                <div className={styles.dashboard_welcome_balance_text}>
                                    {/* <CurrencyFormat value={accountBalance} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={`${currencySymbols}`} /> 
                                    <Link to="#" className={styles.dashboard_welcome_balance_currency}>{currency}</Link> */}
                                </div>
                            </span>
                        </  FlexRow>

                    </div>
                </div>
            </Banner></> );
}
 
export default RefBanner;