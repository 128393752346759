import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import Skeleton from '../skeleton/skeleton';
import ExchangeList from './exchangeList';

const CRYPTO_PAIRS = [
  { base: "BTC", pair: "USD" },
  { base: "BNB", pair: "USD" },
  { base: "SOL", pair: "USD" },
  { base: "ETH", pair: "USD" },
  { base: "LTC", pair: "USD" },
  { base: "USDC", pair: "USD" },
];

const API_BASE_URL = 'https://min-api.cryptocompare.com/data/pricemultifull';
const REFRESH_INTERVAL = 30000;

const useCoinMarketData = (currencySym) => {
  const [isLoading, setIsLoading] = useState(true);
  const [coinMarket, setCoinMarket] = useState([]);
  const [error, setError] = useState(null);

  const fetchCoinMarketData = async () => {
    try {
     
      setError(null);
      const userFiat = JSON.parse(localStorage.getItem('OMS__CUR') || '{}');
      
      const pairData = await Promise.all(
        CRYPTO_PAIRS.map(async ({ base }) => {
          const response = await axios.post(`${API_BASE_URL}?fsyms=${base}&tsyms=${userFiat.name}`);
          const data = response.data.RAW[base][userFiat.name];
          return {
            price: Number(data.PRICE).toFixed(2),
            change: Number(data.CHANGEPCT24HOUR).toFixed(2),
            base,
            cross: userFiat.name,
            symbol: userFiat.symbol,
            marketCap: data.MKTCAP,
          };
        })
      );

      setCoinMarket(pairData);
      localStorage.setItem('__cList', JSON.stringify(pairData));
    } catch (err) {
      setError('Failed to fetch coin market data');
      console.error('Error fetching coin market data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCoinMarketData();
    const intervalId = setInterval(fetchCoinMarketData, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [currencySym]);

  return { isLoading, coinMarket, error };
};

const PairList = ({ currencySym }) => {
  const { isLoading, coinMarket, error } = useCoinMarketData(currencySym);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
      isLoading ? (
        <Skeleton type="PairList" />
      ) : (
        coinMarket && <ExchangeList List={coinMarket} />
      )
   
  );
};

export default memo(PairList);
