// import './css/index_color.css'
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import IndexBody from "./components/body";
import IndexFooter from "./components/footer";
import IndexHeader from "./components/header";



const IndexHome = () => {
// const navigate = useNavigate();
// useEffect(()=>{
// navigate('/en')

// }, [navigate])
    return (<>
        <IndexHeader />
        <IndexBody/>
        <IndexFooter />
    </>);
}

export default IndexHome;