import styled from 'styled-components';

export const ComingSoon = styled.div`
font-size: 24px;
font-weight: 600;
text-align: center;
margin: 80px auto;
opacity: 0.8;
text-transform: uppercase;
letter-spacing: 5px;

@media screen and (min-width: 768px) {
    font-size: 32px;
    font-weight: 600;   
    letter-spacing: 15px;
}

`