import axios from "axios";
import { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import BlogModal from "../blogs/blogModal";
import Skeleton from "../skeleton/skeleton";
import { Card, CardContent, CardHeader, NewsBody, NewsContent, NewsDiv, NewsHeader, NewsImage } from "./dashboardComponent";

const NewsMini = () => {
    const [newsInfo, setNewsInfo] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [blogInfo, setBlogInfo] = useState([]);

    // Memoize the handleReadMore function
    const handleReadMore = useCallback((item) => {
        setBlogInfo(item);
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        const fetchNews = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    'https://min-api.cryptocompare.com/data/v2/news/?lang=EN',
                    { signal: controller.signal }
                );
                setNewsInfo(response.data.Data.slice(0, 3));
            } catch (error) {
                if (error.name === 'AbortError') {
                    // Handle abort
                    return;
                }
                console.error('Error fetching news:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchNews();

        // Cleanup function
        return () => {
            controller.abort();
        };
    }, []);

    // Memoize the news cards rendering
    const newsCards = useMemo(() => {
        if (!newsInfo) return null;
        
        return newsInfo.map((item) => (
            <Card key={item.id}>
                <CardContent>
                    <CardHeader>
                        <NewsDiv>
                            <NewsImage src={item.imageurl} alt={item.title} />
                            <NewsContent>
                                <NewsHeader>
                                    {item.title}
                                </NewsHeader>
                                <NewsBody>
                                    {item.body.substring(0, 30)}...{' '}
                                    <Link
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#newsModal"
                                        onClick={() => handleReadMore(item)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        Read More
                                    </Link>
                                </NewsBody>
                            </NewsContent>
                        </NewsDiv>
                    </CardHeader>
                </CardContent>
            </Card>
        ));
    }, [newsInfo, handleReadMore]);

    return (
        <>
            {isLoading ? <Skeleton type="NewsList" /> : newsCards}
            <BlogModal blog={blogInfo} />
        </>
    );
};

export default NewsMini;
