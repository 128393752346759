import { GetRandomString } from '../wallet/components/random';
import { Card, CardBody, CardBodyContent, CardBodyItemLarge, CardBodyItemMedium, CardContent, CardHeader, CardHeaderContent, CardSmall, ListBlock, NewsBody, NewsContent, NewsDiv, NewsHeader, NewsImage } from './skeletonComponent';
const Skeleton = ({type}) => {
    const PAIR_COUNTER = 6;
    const PairList = () =>{
        return(
        <CardSmall key={GetRandomString(5)}>
            <ListBlock>
                <CardContent >
                    <CardHeader >
                        <CardHeaderContent>
                        </CardHeaderContent>
                    </CardHeader>
                    <CardBody >
                        <CardBodyContent >
                            <CardBodyItemLarge>
                             </CardBodyItemLarge>
                            <CardBodyItemMedium></CardBodyItemMedium>
                        </CardBodyContent>
                    </CardBody>
                </CardContent>
            </ListBlock>
        </CardSmall>)
    }


    const NEWS_COUNTER = 3
    const NewsList =()=>{
        return( <Card key={GetRandomString(5)}>
            <CardContent>
                <CardHeader>
                    <NewsDiv>
                        <NewsImage>
                            </NewsImage>
                            <NewsContent>
                            <NewsHeader>
                            </NewsHeader>
                            <NewsBody>
                            </NewsBody>
                        </NewsContent>
                    </NewsDiv>
                </CardHeader>
                </CardContent> 
        </Card>
           
        )
    }

   if (type === "PairList")  return [...Array(PAIR_COUNTER)].map(PairList);
   else if (type === "NewsList") return [...Array(NEWS_COUNTER)].map(NewsList);
}
 
export default Skeleton;