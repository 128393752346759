import { useState } from "react";
import { Link } from "react-router-dom";
import styles from '../../style/main.module.css'
import { convertTime } from "../wallet/components/getTime";
import { Card, CardContent, CardHeader, NewsBody, NewsContent, NewsDiv, NewsHeader, NewsImage } from "./blogComponent";
import BlogModal from "./blogModal";

const BlogListing = (props) => {
    const newsInfo = props.List
    const [blogInfo, setBlogInfo] = useState([])
    return (<>
        {newsInfo && newsInfo.map((item) => (
            <div className={`${styles.card} ${styles.lg}`} key={item.id}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                        <Card >
                            <CardContent>
                                <CardHeader>
                                    <NewsDiv>
                                        <NewsImage src={item.imageurl} />
                                        <NewsContent>
                                            <NewsHeader>
                                                {item.title}
                                            </NewsHeader>
                                            <NewsBody>
                                                {(item.body).substring(0, 130)}... <Link to={'#'} data-bs-toggle="modal" data-bs-target="#newsModal"  onClick={(e) => {setBlogInfo(item) }}>Read More</Link>
                                                <div className={styles.note_body_right_time}>{convertTime(item.published_on)}</div>
                                            </NewsBody>
                                        </NewsContent>
                                    </NewsDiv>
                                </CardHeader>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>

        ))}
        <BlogModal blog={blogInfo}/>
    </>);
}

export default BlogListing;