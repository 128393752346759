import DashboardFooter from "./components/base";
import DashboardHeader from "./components/header";
import styles from './style/main.module.css'
import Container from "./components/container";
import React  from 'react';
import { useState } from "react";
import { useEffect } from "react";


const Dashboard = () => {
  const [isMobile, setIsMobile] = useState(true)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
          setIsMobile(false)
      }
      else if (window.innerWidth < 767) {
          setIsMobile(true)
      }
  }
  handleResize()
  window.addEventListener("resize", handleResize)
  })
    return (
      <>
        <div className={styles.body} id="dark">
        <DashboardHeader />
        <Container />
        {isMobile ?<DashboardFooter />:<></>}
       
      </div>
      </>
    
    );
}
 
export default Dashboard;