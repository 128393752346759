import React, {  useState } from 'react';
import { Link } from 'react-router-dom';
import { Footer, FooterCenter, FooterIcon, FooterIconWrapper, FooterItem } from './footerStyle';
import styles from '../style/main.module.css'

const DashboardFooter = () => {
    const [active1, setActive1] = useState('')
    const [active2, setActive2] = useState('')
    const [active3, setActive3] = useState('')
    const [active4, setActive4] = useState('')
    return (
        <>
            <Footer>
                <FooterItem>
                <Link to="dashboard" className={`${active1}`} onClick={(e)=>{setActive1(styles.footerLink); setActive3(''); setActive2(''); setActive4('')}}>
                    <FooterIconWrapper>
                        <FooterIcon className="fi fi-sr-home">
                        </FooterIcon>
                    </FooterIconWrapper>
                    Home
                    </Link>
                </FooterItem>
                <FooterItem>
                <Link to="wallet" className={`${active2}`} onClick={(e)=>{setActive2(styles.footerLink); setActive1(''); setActive3(''); setActive4('')}}>
                    <FooterIconWrapper>
                        <FooterIcon className="fi fi-sr-wallet">
                        </FooterIcon>
                    </FooterIconWrapper>
                    Wallet
                    </Link>
                </FooterItem>
                <FooterItem>
                <Link to="wallet/spot" className={`${active3}`} onClick={(e)=>{setActive3(styles.footerLink); setActive1(''); setActive2(''); setActive4('')}}>
                    <FooterIconWrapper>
                        <FooterIcon className="fi fi-sr-apps-add">
                        </FooterIcon>
                    </FooterIconWrapper>
                    Spot/Fiat
                    </Link>
                </FooterItem>
                <FooterItem>
                <Link to="order" className={`${active4}`} onClick={(e)=>{setActive4(styles.footerLink); setActive1(''); setActive2(''); setActive3('')}}>
                    <FooterIconWrapper>
                        <FooterIcon className="fi fi-sr-stats">
                        </FooterIcon>
                    </FooterIconWrapper>
                    Trade
                    </Link>
                </FooterItem>

            </Footer>
        </>
    );
}

export default DashboardFooter;