import React  from 'react';
const DashboardPayments = () => {
    return ( <>
    
    
    
    
    
    </> );
}
 
export default DashboardPayments;