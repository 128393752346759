import axios from 'axios';
import { useEffect, useState, useMemo, memo } from 'react';
import CurrencyFormat from 'react-currency-format';
import Skeleton from '../../market/pages/skeleton/skeleton';
import styles from '../css/index.module.css';
import { H2, IndexSection, IndexSectionDescription, Table, Td, Tr } from '../style/bodyStyle';
import IndexExchangeList from './excangeList';

// Move pairs outside component to prevent recreation on each render
const pairs = [
  { base: "BTC", pair: "USD" },
  { base: "BNB", pair: "USD" },
  { base: "SOL", pair: "USD" },
  { base: "ETH", pair: "USD" },
  { base: "LTC", pair: "USD" },
  { base: "USDT", pair: "USD" }
];

// Memoize CurrencyFormat component for reuse
const MemoizedCurrencyFormat = memo(CurrencyFormat);

// Memoize the CoinPrice component
const CoinPrice = memo(({ coinName }) => {
    const userFiat = JSON.parse(localStorage.getItem('OMS__CUR') || '{}');
    const symbol = userFiat.symbol;
    const currency = userFiat.name;
    const [coinMarket, setCoinMarket] = useState({});
  

  const fetchCoinData = useMemo(() => async () => {
    try {
      const response = await axios.post(`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${coinName}&tsyms=${currency}`);
      const data = response.data.RAW[coinName][currency];
      setCoinMarket(data);
    } catch (error) {
      console.error('Error fetching coin data:', error);
    }
  }, [coinName, currency]);

  useEffect(() => {
    fetchCoinData();
    const interval = setInterval(fetchCoinData, 30000);
    return () => clearInterval(interval);
  }, [fetchCoinData]);

  const getChange = (change) => {
    const className = change < 0 ? styles.sell : change > 0 ? styles.buy : '';
    return (
        change && <div data-bn-type="text" className={`${className} ${styles.coin_changes}`} style={{ direction: "ltr" }}>
      {(change > 0 ? '+' : '') + Number(change).toFixed(2)}%
      </div>
    );
  };

  return (
    <>
      <Td>
        <MemoizedCurrencyFormat 
          value={coinMarket.PRICE} 
          displayType={'text'} 
          decimalScale={2} 
          fixedDecimalScale={true} 
          isNumericString={true} 
          thousandSeparator={true} 
          prefix={`${symbol}`} 
        />
      </Td>
      <Td>{getChange(coinMarket.CHANGEPCT24HOUR)}</Td>
      <Td>{coinMarket.VOLUME24HOUR}</Td>
      <Td>
        <MemoizedCurrencyFormat 
          value={coinMarket.HIGH24HOUR} 
          displayType={'text'} 
          decimalScale={2} 
          fixedDecimalScale={true} 
          isNumericString={true} 
          thousandSeparator={true} 
          prefix={`${symbol}`} 
        />
      </Td>
      <Td>
        <MemoizedCurrencyFormat 
          value={coinMarket.LOW24HOUR} 
          displayType={'text'} 
          decimalScale={2} 
          fixedDecimalScale={true} 
          isNumericString={true} 
          thousandSeparator={true} 
          prefix={`${symbol}`} 
        />
      </Td>
      <Td>
        <MemoizedCurrencyFormat 
          value={coinMarket.MKTCAP} 
          displayType={'text'} 
          decimalScale={2} 
          fixedDecimalScale={true} 
          isNumericString={true} 
          thousandSeparator={true} 
          prefix={`${symbol}`} 
        />
      </Td>
    </>
  );
});

// Memoize PairList component
const PairList = memo(() => {
  const userFiat = JSON.parse(localStorage.getItem('OMS__CUR') || '{}');
  const [isLoading, setIsLoading] = useState(true);
  const [coinMarket, setCoinMarket] = useState([]);

  const fetchPairData = useMemo(() => async () => {
    try {
      const pairData = await Promise.all(pairs.map(async ({ base }) => {
        const response = await axios.post(`https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${base}&tsyms=${userFiat.name}`);
        const data = response.data.RAW[base][userFiat.name];
        return {
          price: Number(data.PRICE).toFixed(2),
          change: Number(data.CHANGEPCT24HOUR).toFixed(2),
          base,
          cross: userFiat.name,
          symbol: userFiat.symbol,
          marketCap: data.MKTCAP
        };
      }));

      setCoinMarket(pairData);
      localStorage.setItem('__cList', JSON.stringify(pairData));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching pair data:', error);
      setIsLoading(false);
    }
  }, [userFiat.name, userFiat.symbol]);

  useEffect(() => {
    fetchPairData();
    const interval = setInterval(fetchPairData, 30000);
    return () => clearInterval(interval);
  }, [fetchPairData]);

  return isLoading ? <Skeleton type="PairList" /> : <IndexExchangeList List={coinMarket} />;
});

const CoinTableRow = memo(({ item, key }) => {
  return (
    <Tr key={key}>
      <Td>
        <div className={styles.coin_table_coin}>
          <img 
            className={styles.coin_table_logo} 
            alt={item.full_name} 
            src={require(`../../market/style/SVG/${item.short_name}.svg`)} 
          />
          <div className={styles.coin_table_coin_name}>
            <div className={styles.coin_table_coin_short}>{item.short_name}</div>
            <div className={styles.coin_table_coin_full}>{item.full_name}</div>
          </div>
        </div>
      </Td>
      <CoinPrice coinName={item.short_name} />
    </Tr>
  );
});

// Memoize IndexMarket component
const IndexMarket = memo(() => {
  const [coinList, setCoinList] = useState([]);
  const [infoLoading, setInfoLoading] = useState(true);

  const fetchCoinList = useMemo(() => async () => {
    try {
      const response = await axios.post('https://api.profoliomarket.com/user/getAllCoin', {});
      setCoinList(response.data.data);
      setInfoLoading(false);
    } catch (error) {
      console.error('Error fetching coin list:', error);
      setInfoLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCoinList();
  }, [fetchCoinList]);

  return (
    <>
      <div className={styles.main_container}>
        <IndexSection>
          <H2>Markets</H2>
          <IndexSectionDescription>Highlight Coin</IndexSectionDescription>
          <div className={styles.index_table}>
            <PairList />
          </div>
        </IndexSection>
      </div>
      <div className={`${styles.card} ${styles.lg}`}>
        <div className={styles.card_inner}>
          <div className={styles.card_content}>
            <div className={styles.table}>
              {!infoLoading && 
                <Table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">24h Change</th>
                      <th scope="col">24h Volume</th>
                      <th scope="col">24h High</th>
                      <th scope="col">24h Low</th>
                      <th scope="col">Market Cap</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coinList.map((item) => (
                      <CoinTableRow key={item.id} item={item} />
                    ))}
                  </tbody>
                </Table>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default IndexMarket;
