import styles from '../../style/main.module.css'
import LinkRules from "./linkRules"
import RefBanner from './referralBanner'
import ReferralTable from "./referralTable"
import ReferralRewards from "./rewardsTable"
import ReferralSummary from "./summary"

const AffiliateBody = () => {

    return (<>
        <div className={styles.main_container}>
            <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={`h2 ${styles.heading_text}`}>
                            Affiliate/Referral Program
                        </div>
                    </div>
                </div>
            </div>
            
            <RefBanner/>
            <div className={styles.dashboard_table}>
               
                <LinkRules />
                <ReferralSummary />
                <ReferralRewards />
                <ReferralTable />

                {/* {isLoading ? <Skeleton type="NewsList"/> : <BlogListing List={newsInfo}/>} */}

            </div>
        </div>
    </>);
}

export default AffiliateBody;