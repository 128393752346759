import styled from 'styled-components';
export const NotFound = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: 10% auto;
color: #d5d5d5;
`
export const NotFoundIcon = styled.i`
font-size: 40px;

`
export const NotFoundText = styled.div`
font-size: 16px;
padding: 10px;
`

export const Table = styled.table`
background-color:var(--table-background-color);
box-sizing: border-box;
min-width: 0px;
color: var(--font-color);
border-collapse: inherit;`

export const Tr = styled.tr`

:hover{
background-color:var(--table-row-hover);
}
`
export const Td = styled.td`
border-width: 0px !important;`
export const FlexRow = styled.div`
display:flex;
flex-direction:row;`
export const FlexColumn = styled.div`
display:flex;
flex-direction:column;
@media screen and (max-width: 767px)
{
    align-items: center;
}`

export const RowColumn = styled.div`
display:flex;
flex-direction:column;
width: 100%;
justify-content: space-evenly;
@media screen and (min-width: 767px)
{
    flex-direction:row;
}
`
export const ColumnRow = styled.div`

    display:flex;
    flex-direction:row;
    @media screen and (min-width: 767px)
    {
        flex-direction:column;
    }`
export const Banner = styled.div`
${FlexRow}{
    margin-bottom:10px;
    span{
        align-self: center;
        font-size:20px;
    }
}`

export const Card = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
display: flex;
width: 100%;
padding: 8px;
`
export const CardContent = styled.div`
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex: 1 1;
    flex-direction: column;`

    export const CardHeader = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    flex: 1 1;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 0;`

    export const CardHeaderContent =  styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    font-size: 12px;
    line-height: 16px;  
    width:75%;

    @media screen and (min-width: 767px)
{
    font-size: 16px;
    line-height: 24px;
}`
export const FAQsImage = styled.img`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 32px;
height: 32px;
flex-shrink: 0;
border-radius: 100%;
`
export const FAQsDiv = styled.div`
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    align-items: center;
    height: fit-content;`
export const FAQsHeader = styled.div`

    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--font-color);
    
    @media screen and (min-width: 1023px)
{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

@media screen and (min-width: 767px)
 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}
`
export const FAQsTags = styled.div`
font-size: 10px;
font-weight: 300;
font-style: italic;
color: var(--warning);`
export const FAQsContent = styled.div`
box-sizing: border-box;
    margin: 0px 0px 0px 24px;
    min-width: 0px;
    display: flex;
    height: fit-content;
    justify-content: center;
    flex-direction: column;`

    export const FAQsBody = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(112, 122, 138);`