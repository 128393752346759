import styled from 'styled-components';
export const Footer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
position: fixed;
bottom: 0;
left: 0;
width: 100%;
z-index: 998;
box-sizing: initial;
height: 70px;
background: var(--card-background-color);
border-radius: 20px 20px 0 0;
@media (min-width: 768px){
    display:none;

}
`
export const FooterItem = styled.div`
flex: 1;
justify-content: center;
height: 100%;
padding: 6px 0;
display: flex;
flex-direction: column;
align-items: center;
position: relative;`
export const FooterCenter = styled.div`
flex: 1;
justify-content: center;
height: 100%;
padding: 6px 0;
display: flex;
flex-direction: row;
flex-direction: column;
align-items: center;
position: relative;`

export const FooterIconWrapper = styled.div`
position: relative;
top: 10px;
padding: 10px;
}`
export const FooterIcon = styled.i`
display: flex;
flex-direction: column;
align-items: center;
`
