import { Link } from 'react-router-dom';
import styles from '../../style/main.module.css';

const formatMarketCap = (value) => {
  if (value >= 1e12) return `${(value / 1e12).toFixed(1)}T`;
  if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`;
  if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
  return value.toLocaleString();
};

const ExchangeList = ({ List }) => {
  if (!List || List.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <>
      {List.map((item) => (
        <div key={`${item.base}/${item.cross}`} className={`${styles.card} ${styles.xs} ${styles.coin_card}`}>
          <div className={`${styles.card_inner} ${styles.card_mini}`}>
            <div className={styles.card_content}>
              <div className={styles.card_header}>
                <div className={styles.coin_id}>
                  <div className={styles.coin_title}>{item.base}</div>
                  <div
                    data-bn-type="text"
                    className={`${styles.coin_changes} ${item.change < 0 ? styles.sell : item.change > 0 ? styles.buy : ''}`}
                    style={{ direction: 'ltr' }}
                  >
                    {item.change > 0 ? '+' : ''}{item.change}%
                  </div>
                </div>
              </div>
              <div className={styles.card_body}>
                <Link id={`coin_${item.base}`} className={styles.coin_ad} to="#" tabIndex="0" aria-label={`${item.base} details`}>
                  <div data-bn-type="text" className={styles.coin_price}>
                    {item.symbol}{Number(item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </div>
                  <div className={styles.coin_Market_cap_wrapper}>
                    <div className={styles.coin_Market_cap}>Market Cap</div>
                    <div data-bn-type="text" className={styles.coin_equivalent}>
                      {item.symbol}{formatMarketCap(item.marketCap)}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ExchangeList;
